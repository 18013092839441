import {
  TextInput,
  Select,
  Button,
  Radio,
  MultiSelect,
  Textarea,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import colors from "../../utilities/design";
import {
  existsAndLength,
  getValueForInput,
  loadingStates,
  validateEmail,
  validateMobile,
} from "../../utilities/utilities";
import styles from "../ServiceProviderForm/ServiceProviderForm.module.css";

import { buUpdate, createServiceProvider } from "../../utilities/apis/serviceProvider";
import { selectUserData } from "../../redux/selectors";
import { createBusinessUnit } from "../../utilities/apis/serviceProvider";

function AddOrganisationForm({ formClose,buId, bussinessUnitData }) {
  const currentUser = useSelector(selectUserData) || {};
  const [orgData, setOrgData] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    businessunitName: "",
    errors: {},
    buData: {
      signName: "",
      signEmail: "",
      poc: "",
      copAddress: "",
    },
  });

  useEffect(()=>{
    if (buId>0) {
      setOrgData({
        loading: loadingStates.NO_ACTIVE_REQUEST,
        businessunitName: bussinessUnitData.name,
        errors: {},
        buData: {
          signName: bussinessUnitData.extraCol.signName,
          signEmail: bussinessUnitData.extraCol.signEmail,
          poc: bussinessUnitData.extraCol.poc,
          copAddress: bussinessUnitData.extraCol.copAddress,
        },
      })
    }
  },[buId]);
  console.log(buId);
console.log(typeof buId);
console.log(orgData);
  
  // const userDetails = useSelector(selectUserData);
  const changeHandler = (name) => (input) => {
    const value = getValueForInput(input);
    setOrgData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const validate = () => {
    const keys = {};

    if (!existsAndLength(orgData.businessunitName)) {
      keys.businessunitName = "Please check Business Unit Name.";
    }

    // if (!existsAndLength(orgData.buData.entityType)) {
    //   keys.entityType = "Please check Entity Type.";
    // }
    // if (!existsAndLength(orgData.buData.orgName)) {
    //   keys.orgName = "Please check organization name.";
    // }
    if (!existsAndLength(orgData.buData.poc)) {
      keys.poc = "Please check Business POC.";
    }
    // if (!existsAndLength(orgData.buData.regAddress)) {
    //   keys.regAddress = "Please check Registered Address.";
    // }
    if (!existsAndLength(orgData.buData.signName)) {
      keys.signName = "Please check Authorised Signatory Name.";
    }
    if (!existsAndLength(orgData.buData.signEmail)) {
      keys.signEmail = "Please check Authorised Signatory Email.";
    }

    if (Object.keys(keys).length) {
      showNotification({
        color: "red",
        title: "Service Record",
        message: "Make sure all fields are filled properly.",
      });
      setOrgData((data) => ({
        ...data,
        errors: keys,
      }));
      return false;
    }
    return true;
  };

  const OrgHandler = async () => {
    if (validate()) {
      setOrgData((data) => ({
        ...data,
        loading: loadingStates.LOADING,
        errors: {},
      }));
      if (buId>0) {
        const orgApiDatabu = {
          businessUnitId: buId ||6,
          orgId: currentUser.mapOrganization|| 1,
          businessunitName: orgData.businessunitName|| "delhi",
          buData: orgData.buData|| {},
        };
        console.log(orgApiDatabu);
        const response = await apiWrapWithErrorWithData(
          buUpdate({
            orgData: orgApiDatabu,
          })
        );
        if (response?.success) {
          setOrgData((prevState) => ({
            ...prevState,
            loading: loadingStates.NO_ACTIVE_REQUEST,
          }));
          showNotification({
            color: "green",
            title: "Service Provider Record Created.",
            message: "Service Provider has been created.",
          });
          if (formClose) {
            formClose();
            window.location.reload();
          }
        } else {
          showNotification({
            color: "red",
            title: "Service Provider Record",
            message:
              response?.message || "Service Provider Record could not be created.",
          });
          setOrgData((prevState) => ({
            ...prevState,
            loading: loadingStates.NO_ACTIVE_REQUEST,
          }));
        }
      } else {
        const orgApiData = {
          orgId: currentUser.mapOrganization,
          businessunitName: orgData.businessunitName,
          buData: orgData.buData,
        };
        const resp = await apiWrapWithErrorWithData(
          createBusinessUnit({
            orgData: orgApiData,
          })
        );
        if (resp?.success) {
          setOrgData((prevState) => ({
            ...prevState,
            loading: loadingStates.NO_ACTIVE_REQUEST,
          }));
          showNotification({
            color: "green",
            title: "Service Provider Record Created.",
            message: "Service Provider has been created.",
          });
          if (formClose) {
            formClose();
            window.location.reload();
          }
        } else {
          showNotification({
            color: "red",
            title: "Service Provider Record",
            message:
              resp?.message || "Service Provider Record could not be created.",
          });
          setOrgData((prevState) => ({
            ...prevState,
            loading: loadingStates.NO_ACTIVE_REQUEST,
          }));
        }

      }
      0

      // console.log("---BC---", orgApiData);

      
      // console.log(resp);
      
    }
  };

  console.log(orgData);

  return (
    <div className="flex flex-col px-4 pb-8">
      <div className={styles.title}>add business unit/region</div>

      <div className="grid grid-cols-2 gap-4  mt-8">

        <TextInput
        required
          placeholder="business unit"
          label="business unit"
          value={orgData.businessunitName}
          onChange={changeHandler("businessunitName")}
          error={orgData.errors.businessunitName}
        />
        <TextInput
        required
          placeholder="authorised signatory name"
          label="authorised signatory name"
          value={orgData.buData.signName}
          onChange={(e)=>{
            setOrgData((prevState)=>({
              ...prevState, 
              buData: {
                ...prevState.buData,
                signName: e.target.value,
              }
            }));
          }}
          error={orgData.errors.signName}
        />

        <TextInput
        required
          placeholder="authorised signatory email"
          label="authorised signatory email"
          type="email"
          value={orgData.buData.signEmail}
          onChange={(e)=>{
            setOrgData((prevState)=>({
              ...prevState, 
              buData: {
                ...prevState.buData,
                signEmail: e.target.value,
              }
            }));
          }}
          error={orgData.errors.signEmail}
        />
        <TextInput
        required
          placeholder="business POC"
          label="business POC"
          value={orgData.buData.poc}
          onChange={(e)=>{
            setOrgData((prevState)=>({
              ...prevState, 
              buData: {
                ...prevState.buData,
                poc: e.target.value,
              }
            }));
          }}
          error={orgData.errors.poc}
        />

        <Textarea
          placeholder="corporate address"
          label="corporate address"
          rows={2}
          value={orgData.buData.copAddress}
          onChange={(e)=>{
            setOrgData((prevState)=>({
              ...prevState, 
              buData: {
                ...prevState.buData,
                copAddress: e.target.value,
              }
            }));
          }}
        />
      </div>

      <div className="flex justify-end mt-3">
        <Button
          onClick={OrgHandler}
          disabled={orgData.loading === loadingStates.LOADING}
          style={{
            backgroundColor: "#46BDE1",
            borderRadius: "0.5rem",
            color: "#F5F5F5",
          }}
        >
          {orgData.loading === loadingStates.LOADING && (
            <BeatLoader color={colors.primary} size={10} />
          )}
          {orgData.loading === loadingStates.NO_ACTIVE_REQUEST && (
            <span> save</span>
          )}
        </Button>
      </div>
    </div>
  );
}

export default AddOrganisationForm;
