/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mantine/core";
import { loadingStates } from "../utilities/utilities";
import { useNavigate } from "react-router-dom";
import { apiWrapWithErrorWithData } from "../utilities/apiHelpers"; // Import the necessary functions
import { tasksAnalytics } from "../utilities/apis/tasks"; // Import the necessary functions
import "./DashboardAgreementsManager.css";
import renewals from "./ImagesDashboard/renewals.png";
import tasks from "./ImagesDashboard/tasks.png";
import e_stamps from "./ImagesDashboard/e_stamps.png";
import { selectUserData } from "../redux/selectors";
import { useSelector } from "react-redux";
import { showNotification } from "@mantine/notifications";

const DashboardAgreementsManager1 = ({ month }) => {
  const navigate = useNavigate();
  const currentUser = useSelector(selectUserData) || {};
  const loginAs = currentUser.loginAs;
  const [BoxData, setBoxData] = useState([
    {
      title: "renewals",
      icon: null,
      value: null,
      color: "red-400",
      link: "Renewals",
      imgSrc: renewals,
    },
    {
      title: "tasks",
      icon: null,
      value: null,
      color: "blue-900",
      link: "Tasks",
      imgSrc: tasks,
    },
    {
      title: "e-stamps",
      icon: null,
      value: null,
      color: "green-900",
      link: "EStamps",
      imgSrc: e_stamps,
      url: "https://www.emsigner.com/Areas/login?ReturnUrl=%2feMsecure%2feStamping%2feStampingSummary",
    },
  ]);


    const handleBoxClick = (title) => {
      const accessRules = {
        EStamps: [1, 2, 4],
        Tasks: [1, 2, 3, 4, 5, 6, 7],
        Renewals: [1, 2, 4],
      };
      const allowedIds = accessRules[title];
      if (allowedIds && allowedIds.includes(loginAs.id)) {
        if (title === "EStamps") {
          window.location.href = "https://www.emsigner.com/Areas/login?ReturnUrl=%2feMsecure%2feStamping%2feStampingSummary";
           // Redirect to E-Stamps URL
        } else if (title === "Renewals") {
         navigate('/app/agreements_manager/renewals');
        }else if (title === "Tasks") {
          navigate('/app/agreements_manager/tasks/pending');
        }
      } else  {
        showNotification({
          color: "red",
          title: 'Access ',
          message: 'You Do not have Access!',
        })
      }

      
    };

  // const [configs, setConfigs] = useState({
  //   loading: loadingStates.NO_ACTIVE_REQUEST,
  //   data: null,
  // });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setConfigs({ ...configs, loading: loadingStates.LOADING });
  //     try {
  //       const resp = await apiWrapWithErrorWithData(tasksAnalytics({ month }));
  //       if (resp?.success && resp?.analytics) {
  //         setConfigs({
  //           ...configs,
  //           data: resp.analytics,
  //           loading: loadingStates.NO_ACTIVE_REQUEST,
  //         });
  //       } else {
  //         // showNotification({
  //         //   color: 'red',
  //         //   title: 'Case Analytics',
  //         //   message: 'Failed to load case analytics.',
  //         // });
  //         setConfigs({ ...configs, loading: loadingStates.NO_ACTIVE_REQUEST });
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       // Handle errors or show a notification
  //       // showNotification({
  //       //   color: 'red',
  //       //   title: 'Error',
  //       //   message: 'Failed to fetch data.',
  //       // });
  //       setConfigs({ ...configs, loading: loadingStates.NO_ACTIVE_REQUEST });
  //     }
  //   };

  //   fetchData();
  // }, [month]);

  return (
    < >
      {BoxData.map((box, index) => (
          <div
            key={index}
            className="flex py-10 w-1/3"
            onClick={() => handleBoxClick(box.link)}
          >
            <div className="flex itemr1" >

              <div className="image flex">
                <img src={box.imgSrc} alt={box.title} />
              </div>
              <div className="text ">
                <div
                  className="heading text-center"
                  style={{ fontFamily: "Mullish, sans-serif" }}
                >
                  {box.title}
                </div>
                <div
                  className="number"
                  style={{ fontFamily: "Inter, sans-serif" }}
                >
                  {box.value}
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default DashboardAgreementsManager1;
