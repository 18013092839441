import React, { useEffect, useState } from "react";
import { Button, Stepper } from "@mantine/core";
import { handleChangeUpload } from "../BulkAgreement/utils";
import { addDays, parseISO, subDays } from "date-fns";
import flat from "flat";
import { Progress} from "@mantine/core";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../redux/selectors";
import {
  createAgreement,
  putLegacyFlowJson,
} from "../../../utilities/apis/agreements";
import { apiWrapWithErrorWithData } from "../../../utilities/apiHelpers";
import { useNavigate } from 'react-router-dom';

const CreateBulkLegacyAgreement = () => {
  const [value, setValue] = useState(0)
  const currentUser = useSelector(selectUserData);
  const navigate = useNavigate(); 
  let columns = [
    "typeOfAgreement",
    "relationshipType",
    "relationshipWith",
    "titleOfAgreement",
    "requestingUserName",
    "requestingUserEmail",
    "requestingUserPhone",
    "requestingUserDesignation",
    "requestingUserDepartment",
    "dateOfContract",
    "noOfYears",
    "noOfMonths",
    "reminderTimeInDays",
    "location",
    "firstPartyType",
    "firstPartyEntityType",
    "firstPartyName",
    "secondPartyType",
    "secondPartyEntityType",
    "secondPartyName",
  ];

  const [flowJsonData, setFlowJsonData] = useState({});
  useEffect(() => {
    if (currentUser.loginAs.id === 3) {
      setFlowJsonData({
        3: [{ userId: currentUser.id, isLegacy: true }],
        4: [],
      });
    } else if (currentUser.loginAs.id === 4) {
      setFlowJsonData({
        3: [],
        4: [{ userId: currentUser.id, isLegacy: true }],
      });
    }
  }, []);
  console.log(flowJsonData);
  console.log(currentUser);

  const handleDownload = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += columns.join(",") + "\r\n";

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "bulklegacyagreements.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  let x = 0;

  useEffect(()=>{
    if(value === 100){
      navigate('/app/agreements_manager/docs-locker');
    }
  }, [value]);

  const handleFileChange = async (e) => {
    try {
      const [csvData, formData] = await handleChangeUpload(e);
      console.log("csvData: ", csvData);

      for (let index = 0; index < csvData.length; index++) {
        let month, year, day;
        [day, month, year] = csvData[index].dateOfContract.split("/");
        const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
          2,
          "0"
        )}T00:00:00.000Z`;
        const contractMonths = csvData[index].noOfMonths
          ? parseInt(csvData[index].noOfMonths, 10) * 30
          : null;
        const contractYears = csvData[index].noOfYears
          ? parseInt(csvData[index].noOfYears, 10) * 365
          : null;
        const totalDays = contractMonths + contractYears;

        const contractDueDate = addDays(parseISO(formattedDate), totalDays);

        const formValues = {
          typeOfAgreement: csvData[index].typeOfAgreement,
          titleOfAgreement: csvData[index].titleOfAgreement,
          requestingUserDetails: {
            name: csvData[index].requestingUserName,
            email: csvData[index].requestingUserEmail,
          },
          dateOfContract: formattedDate,
          contractTermInDays: totalDays,
          noOfYears: parseInt(csvData[index].noOfYears, 10),
          noOfMonths: parseInt(csvData[index].noOfMonths, 10),
          location: csvData[index].location,
          reminderTimeInDays: parseInt(csvData[index].reminderTimeInDays, 10),
          dueDateOfContract: contractDueDate,
          reminderTime: subDays(
            contractDueDate,
            csvData[index].reminderTimeInDays
          ),
          contractQuillJsDelta: csvData[index].contractQuillJsDelta,
          firstParty: {
            name: csvData[index].firstPartyName,
            type: csvData[index].firstPartyType,
            entityType: csvData[index].firstPartyEntityType,
          },
          secondParty: {
            name: csvData[index].secondPartyName,
            type: csvData[index].secondPartyType,
            entityType: csvData[index].secondPartyEntityType,
          },
          thirdParty: {},
          fourthParty: {},
          fifthParty: {},
          formCompleted: true,
          relationshipType: csvData[index].relationshipType,
          relationshipWith: csvData[index].relationshipWith,
          businessUnit: currentUser.mapBusinessunit,
          status: "SIGNED",
        };
        const agreementData = {
          json: flat.unflatten({ ...formValues, isLegacy: true }),
          flatted: { ...formValues, isLegacy: true },
        };
        console.log(formValues);
        x = (index + 1) / csvData.length;
        const respo = await apiWrapWithErrorWithData(
          createAgreement({ agreementData, ...formValues })
        );
        await apiWrapWithErrorWithData(
          putLegacyFlowJson(respo.agreement.id, flowJsonData)
        );
        setValue(x*100);
        console.log(x);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-4">
        <h1 className="text-center text-3xl font-bold text-gray-800 mb-4">
          Create Bulk Legacy Agreements
        </h1>
        <h3 className="text-lg text-gray-600 mb-2">
          Download CSV file by clicking on Download CSV button.
        </h3>
        <button
          className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 transform hover:-translate-y-0.5 transition-all duration-300"
          onClick={handleDownload}
        >
          Download CSV
        </button>
        <h3 className="text-lg text-gray-600 mt-4 mb-2">
          Fill all the required fields and upload file.
        </h3>
        <div className="flex items-center mt-2">
          <input
            type="file"
            id="file"
            className="hidden"
            onChange={(e) => handleFileChange(e)}
          />
          <label
            htmlFor="file"
            className="text-white bg-gradient-to-r from-green-500 via-green-600 to-green-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 cursor-pointer"
          >
            Upload File
          </label>
        </div>
      </div>
      <Progress className="mt-20 mx-auto" style={{width: "70%"}}  radius="lg" size="lg" value={value} striped animated />
    </>

  );
};

export default CreateBulkLegacyAgreement;
