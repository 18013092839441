/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mantine/core";
import { loadingStates } from "../utilities/utilities";
import { Paperclip } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { apiWrapWithErrorWithData } from "../utilities/apiHelpers"; // Import the necessary functions
import {
  tasksAnalytics,
  AgreementManagerAnalytics,
} from "../utilities/apis/tasks"; // Import the necessary functions
import DashboardAgreementsManager1 from "./DashboardAgreementsManager1";
import "./DashboardAgreementsManager.css";
import total_img from "./ImagesDashboard/total_contract.png";
import review from "./ImagesDashboard/review.png";
import signed from "./ImagesDashboard/signed.png";
import signing_status from "./ImagesDashboard/signing_status.png";
import approval_status from "./ImagesDashboard/approval_status.png";
import { selectUserData } from "../redux/selectors";
import { useSelector } from "react-redux";
import { showNotification } from "@mantine/notifications";

const DashboardAgreementsManager = ({ month }) => {
  const navigate = useNavigate();

  const currentUser = useSelector(selectUserData) || {};
  const loginAs = currentUser.loginAs;

  // console.log("User right now logged in as", loginAs);

  const [BoxData, setBoxData] = useState([
    {
      title: "total agreements",
      icon: null,
      value: "",
      color: "red-400",
      imgSrc: total_img,
    },
    {
      title: "review status",
      icon: null,
      value: "",
      color: "blue-900",
      imgSrc: review,
    },
    {
      title: "approval status",
      icon: null,
      value: "",
      color: "purple-900",
      imgSrc: approval_status,
    },
    {
      title: "signature status",
      icon: null,
      value: "",
      color: "green-900",
      imgSrc: signing_status,
    },
    {
      title: "signed",
      icon: null,
      value: "",
      color: "pink-900",
      imgSrc: signed,
    },
  ]);

  const fetchAgrManagerAnalytics = async () => {
    const resp = await apiWrapWithErrorWithData(AgreementManagerAnalytics());
    if (resp?.success && resp?.analytics) {
      //console.log('#fetchAgrManagerAnalytics  ',resp);
      const analytics = resp?.analytics;
      let _box = [];
      for (const box of BoxData) {
        if (box.title === "total agreements") {
          const temp = analytics.find((ele) => ele.status === "Total");
          box.value = temp.count + "";
        } else if (box.title === "review status") {
          const temp = analytics.find((ele) => ele.status === "Reviewer");
          box.value = temp.count + "";
        } else if (box.title === "signature status") {
          const temp = analytics.find((ele) => ele.status === "Sign-Auth");
          box.value = temp.count + "";
        } else if (box.title === "approval status") {
          const temp = analytics.find((ele) => ele.status === "Approver");
          box.value = temp.count + "";
        } else if (box.title === "signed") {
          const temp = analytics.find((ele) => ele.status === "Signed");
          box.value = temp.count + "";
        } else if (box.title === "initiator status") {
          const temp = analytics.find((ele) => ele.status === "Initiator");
          box.value = temp.count + "";
        } else if (box.title === "editor status") {
          const temp = analytics.find((ele) => ele.status === "Editor");
          box.value = temp.count + "";
        } else if (box.title === "other") {
          const temp = analytics.find((ele) => ele.status === "Other");
          box.value = temp.count + "";
        }
        _box.push(box);
      }
      //setBoxData({
      //  ..._box
      //});

      // console.log("#BoxData ", BoxData);
    }
  };

  const handleBoxClick = (title) => {
    const currentPath = "/app/agreements_manager";
    const newPath = `${currentPath}/${title.toLowerCase().replace(/\s/g, "-")}`;
    const accessRules = {
      "total agreements": [1, 2, 3, 4, 5, 6, 7],
      "review status": [1, 2, 4, 5],
      "signature status": [1, 2, 4, 7],
      "approval status": [1, 2, 4, 6],
      "signed": [1, 2, 4],
    };

    const allowedIds = accessRules[title];

    if (allowedIds && allowedIds.includes(loginAs.id)) {
      navigate(newPath);
    } else {
      showNotification({
        color: "red",
        title: 'Access ',
        message: 'You Do not have Access!',
      });
    }
  };

  const [configs, setConfigs] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    data: null,
  });

  useEffect(() => {
    fetchAgrManagerAnalytics();
    const fetchData = async () => {
      setConfigs({ ...configs, loading: loadingStates.LOADING });
      try {
        const resp = await apiWrapWithErrorWithData(tasksAnalytics({ month }));
        if (resp?.success && resp?.analytics) {
          setConfigs({
            ...configs,
            data: resp.analytics,
            loading: loadingStates.NO_ACTIVE_REQUEST,
          });
        } else {
          // showNotification({
          //   color: 'red',
          //   title: 'Case Analytics',
          //   message: 'Failed to load case analytics.',
          // });
          setConfigs({ ...configs, loading: loadingStates.NO_ACTIVE_REQUEST });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors or show a notification
        // showNotification({
        //   color: 'red',
        //   title: 'Error',
        //   message: 'Failed to fetch data.',
        // });
        setConfigs({ ...configs, loading: loadingStates.NO_ACTIVE_REQUEST });
      }
    };

    fetchData();
  }, [month]);

  return (
    <div>
      <div className="container mx-auto  flex flex-wrap">
        {BoxData.map((box, index) => (
          <div
            key={index}
            className={`flex py-10 ${index < 3 ? "w-1/3" :  " w-1/2"}`}
            onClick={() => handleBoxClick(box.title)}
          >
            <div className={`flex ${index < 3 ? "itemr1" : (index === 4 ? "itemr2 margin5" : (index === 3 ? "margin4 itemr2" : "nulll"))}`}>

              <div className="image flex">
                <img src={box.imgSrc} alt={box.title} />
              </div>
              <div className="text ">
                <div
                  className="heading text-center"
                  style={{ fontFamily: "Mullish, sans-serif" }}
                >
                  {box.title}
                </div>
                <div
                  className="number"
                  style={{ fontFamily: "Inter, sans-serif" }}
                >
                  {box.value}
                </div>
              </div>
            </div>
          </div>
        ))}
        <DashboardAgreementsManager1 />
      </div>
      
    </div>
  );
};

export default DashboardAgreementsManager;
